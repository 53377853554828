import MarkdownIt from 'markdown-it';
import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ArticleData } from '../../../types/articleListPage';
import { getUploadUrl } from '../../../utils/general';
import { LocaleType } from '../../../utils/i18n';
import './articleContent.scss';

const ArticleContent: React.FC<ArticleData> = ({ attributes }): JSX.Element => {
  const { body, publishedAt, title, caption, mediaContact, summary, image } =
    attributes;

  const { i18n } = useContext(GlobalContext);

  const currentLocale: LocaleType = useLocaleFromRouter();

  const renderContactCard = () => {
    return (
      <div className="contact">
        <div className="contact-title">
          {i18n?.t('article.media_conact.title').toUpperCase()}
        </div>
        {mediaContact?.name && <div>{mediaContact?.name}</div>}
        {mediaContact?.position && <div>{mediaContact?.position}</div>}
        {mediaContact?.email && <div>{mediaContact?.email}</div>}
        {mediaContact?.phone && <div>{mediaContact?.phone}</div>}
      </div>
    );
  };

  const md = MarkdownIt();

  return (
    <div className="article-content">
      <div className="article-header">
        {title && <h3 className="title">{title}</h3>}
        {publishedAt && (
          <div className="date">
            {new Date(publishedAt).toLocaleDateString(currentLocale, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </div>
        )}

        {summary && <div className="summary">{summary}</div>}
      </div>
      {image?.data && (
        <div className="image-container">
          <img
            className="image"
            width={'100%'}
            src={getUploadUrl(image.data.attributes.url)}
            alt={image.data.attributes.alternativeText}
          />
          {caption && <div className="caption">{caption}</div>}
        </div>
      )}

      {body && (
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: md.render(body) }}
        ></div>
      )}
      {mediaContact && renderContactCard()}
    </div>
  );
};

export default ArticleContent;
