import { graphql } from 'gatsby';
import React from 'react';

import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import ArticleContent from '../components/news/articleContent/articleContent';
import { PageBase } from '../context/global-context';
import { ArticleData } from '../types/articleListPage';
import { PostType } from '../types/general';
import { PageContext } from '../types/page';
import { useI18n } from '../utils/i18n';

export const query = graphql`
  query Article($id: ID) {
    creegraphql {
      article(id: $id) {
        data {
          attributes {
            body
            caption
            image {
              data {
                attributes {
                  alternativeText
                  url
                }
              }
            }
            localizations {
              data {
                attributes {
                  locale
                  slug
                }
              }
            }
            mediaContact {
              email
              name
              phone
              position
            }
            publishedAt
            slug
            summary
            title
          }
        }
      }
    }
  }
`;

const ArticlePage: React.FC<{
  pageContext: PageContext;
  data: {
    creegraphql: {
      article: {
        data: ArticleData;
      };
    };
  };
}> = ({ pageContext, data }) => {
  const { translations } = pageContext;

  const i18n = useI18n(translations);

  const article = data.creegraphql.article;

  const instance = { ...article.data, postType: PostType.article };

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <Banner
          title={i18n.t('article_page.title')}
          bannerType="common"
          pageType={pageType.item}
          crumbs={{
            previousPageTitle: i18n.t('news.title'),
            previousPageLink: `${i18n.t('news.slug')}/1`,
            crumbTitle: i18n.t('article_page.crumbs.title'),
          }}
        />
        <main>
          {article.data && (
            <ArticleContent attributes={article.data.attributes} />
          )}
        </main>
      </Layout>
    </PageBase>
  );
};

export default ArticlePage;
